/** @format */

/* .woocommerce-page .entry-content .woocommerce form .form-row label {
  margin-bottom: 0.2rem !important;
}

.woocommerce-page .entry-content .woocommerce form .form-row {
  margin: 1.2rem 0 0 0 !important;
}
 */

/* .block-editor-block-list__layout>div:not(.sm2-toc,.woocommerce-notices-wrapper)+div:not(.sm2-toc,.sm2-menu-subpages,.block-core-columns,.acf-block-preview,.wp-block-columns):not(:where(.has-background):last-of-type),.entry-content>div>div:not(.sm2-toc,.woocommerce-notices-wrapper)+div:not(.sm2-toc,.sm2-menu-subpages,.block-core-columns,.acf-block-preview,.wp-block-columns):not(:where(.has-background):last-of-type) {
  margin-top: 3.25rem!important;
  margin-bottom: 0!important
}

@media (min-width: 600px) {
  .block-editor-block-list__layout>div:not(.sm2-toc,.woocommerce-notices-wrapper)+div:not(.sm2-toc,.sm2-menu-subpages,.block-core-columns,.acf-block-preview,.wp-block-columns):not(:where(.has-background):last-of-type),.entry-content>div>div:not(.sm2-toc,.woocommerce-notices-wrapper)+div:not(.sm2-toc,.sm2-menu-subpages,.block-core-columns,.acf-block-preview,.wp-block-columns):not(:where(.has-background):last-of-type) {
      margin-top:5rem!important
  }
}

@media (min-width: 980px) {
  .block-editor-block-list__layout>div:not(.sm2-toc,.woocommerce-notices-wrapper)+div:not(.sm2-toc,.sm2-menu-subpages,.block-core-columns,.acf-block-preview,.wp-block-columns):not(:where(.has-background):last-of-type),.entry-content>div>div:not(.sm2-toc,.woocommerce-notices-wrapper)+div:not(.sm2-toc,.sm2-menu-subpages,.block-core-columns,.acf-block-preview,.wp-block-columns):not(:where(.has-background):last-of-type) {
      margin-top:6rem!important
  }
} */

[multiple],
[type='date'],
[type='datetime-local'],
[type='email'],
[type='month'],
[type='number'],
[type='password'],
[type='search'],
[type='tel'],
[type='text'],
[type='time'],
[type='url'],
[type='week'],
input,
select {
  border-color: rgb(143 157 168 / var(--tw-border-opacity));
  padding: 0.5rem !important;
  background-color: #fff !important;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  --tw-text-opacity: 1;
  color: rgb(51 51 51 / var(--tw-text-opacity));
}

.entry-content .woocommerce {
  margin-top: 3.25rem !important;
  margin-bottom: 0 !important;
}

@media (min-width: 600px) {
  .entry-content .woocommerce {
    margin-top: 5rem !important;
  }
}

@media (min-width: 980px) {
  .entry-content .woocommerce {
    margin-top: 6rem !important;
  }
}

.sm2-marquee__media img {
  min-height: 275px;
}

.sm2-marquee__head_ {
  margin-left: auto;
  margin-right: auto;
  max-width: 72rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 2.5rem;
  line-height: 3rem;
  font-weight: 700;
}

@media (min-width: 768px) {
  .sm2-marquee__head_ {
    font-size: 3.125rem;
    line-height: 3.75rem;
  }
}

@media (min-width: 980px) {
  .sm2-marquee__head_ {
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 5rem;
    line-height: 5.625rem;
  }
}

@media screen and (max-width: 767px) {
  .full-width-button {
    width: 100%;
  }
}

.woocommerce-page .sm2-marquee + article.product {
  margin-top: 3.25rem;
}

@media (min-width: 600px) {
  .woocommerce-page .sm2-marquee + article.product {
    margin-top: 5rem;
  }
}

@media (min-width: 980px) {
  .woocommerce-page .sm2-marquee + article.product {
    margin-top: 6rem;
  }
}

.woocommerce-page .entry-content .woocommerce form.checkout .woocommerce-terms-and-conditions-wrapper {
  margin-top: 0;
  border-top-width: 0;
  --tw-border-opacity: 0;
  padding-top: 0;
}

#add_payment_method #payment div.form-row,
.woocommerce-cart #payment div.form-row,
.woocommerce-checkout #payment div.form-row {
  padding: 0;
}

#calendar .day-active:hover {
  background-color: rgba(71, 119, 151, 0.3) !important;
}

:root {
  /* fonts */
  --font-inter: Inter;
  --font-kaisei-harunoumi: 'Kaisei HarunoUmi';

  /* font sizes */
  --font-size-lg: 18px;
  --font-size-5xl: 24px;
  --font-size-base: 16px;
  --font-size-sm: 14px;
  --font-size-xs: 12px;

  /* Colors */
  --light-primary-contrast: #fff;
  --button: #3a6486;
  --color-dimgray-100: #535e66;
  --primary: #7d8c98;
  --back-for-fields-light: #f1f3f4;
  --color-black: #000;

  /* Gaps */
  --gap-3xs: 10px;
  --gap-xl: 20px;
  --gap-9xs: 4px;
  --gap-5xl: 24px;
  --gap-7xs: 6px;
  --gap-11xs: 2px;
  --gap-xs: 12px;

  /* Paddings */
  --padding-xl: 20px;
  --padding-4xs: 9px;
  --padding-3xs: 10px;
  --padding-9xs: 4px;

  /* border radiuses */
  --br-5xs: 8px;
}
